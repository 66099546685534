// external dependencies
import React, { useState } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import FailedIcon from '@material-ui/icons/Error';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InputMask from 'react-input-mask';

// local dependencies
import useForm from '../../hooks/useForm';

const StyledFormWrapper = styled.div`
  padding-top: ${(props) => (props.noPaddingTop ? '0px' : '25px')};
  min-width: 500px;

  @media (max-width: 1024px) {
    min-width: 400px;
  }

  @media (max-width: 900px) {
    min-width: unset;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & .MuiTextField-root {
    margin: 5px;
    background: white;
  }

  & .MuiFormControl-root {
    margin: 5px;
    width: 100%;
  }

  & .MuiOutlinedInput-root {
    background: white;
  }

  & .MuiSelect-root {
    background: white;
  }

  @media (max-width: 800px) {
    display: block;
    & .MuiTextField-root {
      margin: 5px auto;
    }

    & .MuiFormControl-root {
      margin: 5px auto;
    }
  }
`;

const StyledFormMessage = styled.div`
  width: 100%;
  min-height: 350px;
  min-width: 350px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.blue};
  background-color: white;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    min-width: 500px;
  }
`;

// component definition
const Form = ({ formName, noPaddingTop, buttonText = 'Submit Request' }) => {
  const { values, updateValue } = useForm({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    projectLocation: '',
    projectInterest: '',
    referralTypes: '',
    comment: '',
  });

  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const encodeFormData = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeFormData({
        'form-name': formName,
        ...values,
      }),
    })
      .then((data) => {
        // setFormSuccess(true);
        return window.location.assign('/thank-you');
      })
      .catch((error) => {
        setFormError(true);
      });
  };

  return (
    <>
      {formSuccess ? (
        <StyledFormMessage>
          <div>
            <SuccessIcon fontSize='large' htmlColor='green' />{' '}
            <h5>Message sent</h5>
            <p>We will be in touch soon.</p>
          </div>
        </StyledFormMessage>
      ) : formError ? (
        <StyledFormMessage>
          {' '}
          <div>
            <FailedIcon fontSize='large' htmlColor='red' />{' '}
            <h5>Form submission failed.</h5>
            <p>
              Please try again or call us at{' '}
              <a href='tel:+18009429255'>(800) 942-9255</a>.
            </p>
          </div>
        </StyledFormMessage>
      ) : (
        <StyledFormWrapper noPaddingTop={noPaddingTop}>
          <form
            name={formName}
            data-netlify='true'
            netlify-honeypot='bot-field'
            // method='post'
            // action='/success'
            onSubmit={handleSubmit}
          >
            <input type='hidden' name={formName} value='contact' />
            <input type='hidden' name='bot-field' />
            <StyledInputWrapper>
              <TextField
                fullWidth
                name='firstname'
                id='first-name'
                label='First Name'
                variant='outlined'
                required
                value={values.firstname}
                onChange={updateValue}
              />
              <TextField
                fullWidth
                name='lastname'
                id='last-name'
                label='Last Name'
                variant='outlined'
                required
                value={values.lastname}
                onChange={updateValue}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <InputMask
                mask='(999) 999-9999'
                value={values.phone}
                disabled={false}
                onChange={updateValue}
                maskChar=' '
              >
                {() => (
                  <TextField
                    id='phone-number'
                    type='tel'
                    name='phone'
                    label='Phone Number'
                    variant='outlined'
                    required
                  />
                )}
              </InputMask>

              <TextField
                id='email'
                name='email'
                label='Email'
                variant='outlined'
                type='email'
                required
                value={values.email}
                onChange={updateValue}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <TextField
                fullWidth
                name='projectLocation'
                id='project-location'
                label='Project Location'
                variant='outlined'
                value={values.projectLocation}
                required
                onChange={updateValue}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <FormControl variant='outlined' required>
                <InputLabel name='projectInterest' id='project-interest'>
                  Project of interest
                </InputLabel>
                <Select
                  autoWidth
                  labelId='project-interest'
                  id='project-interest'
                  name='projectInterest'
                  value={values.projectInterest}
                  onChange={updateValue}
                  variant='outlined'
                  required
                >
                  <MenuItem value='Architectural Consulting'>
                    Architectural Consulting
                  </MenuItem>
                  <MenuItem value='Building Information Modeling'>
                    Building Information Modeling
                  </MenuItem>
                  <MenuItem value='Design Engineering'>
                    Design Engineering
                  </MenuItem>
                  <MenuItem value='DIY Material Sales'>
                    DIY Material Sales
                  </MenuItem>
                  <MenuItem value='Repairs'>Repairs</MenuItem>
                  <MenuItem value='Installation'>Installation</MenuItem>
                  <MenuItem value='Licenses '>Licenses </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant='outlined' required>
                <InputLabel id='how-did-you-find-us'>
                  How did you find us?
                </InputLabel>
                <Select
                  labelId='how-did-you-find-us'
                  id='how-did-you-find-us'
                  name='referralType'
                  value={values.referralType}
                  onChange={updateValue}
                  required
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='Google'>Google</MenuItem>
                  <MenuItem value='Yahoo'>Yahoo</MenuItem>
                  <MenuItem value='Online Advertisement'>
                    Online Advertisement
                  </MenuItem>
                  <MenuItem value='Paper Brochure'>Paper Brochure</MenuItem>
                  <MenuItem value='Magazine'>Magazine</MenuItem>
                  <MenuItem value='Facebook'>Facebook</MenuItem>
                  <MenuItem value='Twitter'>Twitter</MenuItem>
                  <MenuItem value='Referral'>Referral</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </Select>
              </FormControl>
            </StyledInputWrapper>
            <StyledInputWrapper>
              <TextField
                id='outlined-multiline-static'
                label='Additional Comments'
                name='comment'
                value={values.comment}
                multiline
                rows={4}
                required
                variant='outlined'
                fullWidth
                onChange={updateValue}
              />
            </StyledInputWrapper>
            <Button
              color='primary'
              variant='contained'
              disableElevation
              type='submit'
              endIcon={<ArrowForwardIcon />}
              style={{ margin: '25px 5px 0 5px', backgroundColor: '#ebaa20' }}
            >
              {buttonText}
            </Button>
          </form>
        </StyledFormWrapper>
      )}
    </>
  );
};

export default Form;
