// external dependencies
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  h6 {
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    align-items: center;
    letter-spacing: 1px;
    color: ${(props) => props.theme.colors.gold};
    margin-bottom: 1rem;
  }

  h6:before {
    content: '';
    border-top: 4px solid;
    margin: ${(props) => (props.center ? '0 10px;' : '0 10px 0 0')};
    flex: 0 0 40px;
  }

  h6:after {
    content: '';
    border-top: ${(props) => props.center && '4px solid'};
    margin: ${(props) => (props.center ? '0 10px;' : '0 10px 0 0')};
    flex: 0 0 40px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

// component definition
const SuperText = ({ center, title }) => {
  return (
    <StyledWrapper center={center}>
      <h6>{title}</h6>
    </StyledWrapper>
  );
};

export default SuperText;
